import React from "react";
import Projects from "../components/Projects/Projects";

const FAQSection = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};

export default FAQSection;
