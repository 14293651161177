import React from "react";

const FeaturesSection = () => {
  const commonStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  const features = [
    {
      id: "mutli-users",
      title: "Artificial Intelligence",
      description: "Advanced AI Integration.",
      imageUrl:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae7031_People.png",
      imageSrcSet:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae7031_People-p-500.png 500w, https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae7031_People.png 520w",
    },
    {
      id: "all-with-love",
      title: "User Interface",
      description: "User-Centric Design Philosophy",
      imageUrl:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5beb520331e44acca36_Heart.png",
      imageSrcSet:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5beb520331e44acca36_Heart-p-500.png 500w, https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5beb520331e44acca36_Heart.png 520w",
    },
    {
      id: "reasonable-prices",
      title: "Multi-Tool Integration",
      description: "Educational and Development Tools",
      imageUrl:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae700f_Tag.png",
      imageSrcSet:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae700f_Tag-p-500.png 500w, https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be6cb11eb52aae700f_Tag.png 520w",
    },
    {
      id: "all-in-never-out",
      title: "Development",
      description: "Sustainable Development",
      imageUrl:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be26d317754205b69b_Layers.png",
      imageSrcSet:
        "https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be26d317754205b69b_Layers-p-500.png 500w, https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537b5be26d317754205b69b_Layers.png 520w",
    },
    // Additional features can be added here following the same structure.
  ];

  return (
    <div className="section" style={{ marginTop: -200 }}>
      <div className="container">
        <div className="padding-section">
          <div className="center-text">
            <div className="title-holder">
              <div className="title-container" style={commonStyle}>
                <h2 className="title">Seamless Solutions</h2>
              </div>
              <div className="paragraph-holder" style={commonStyle}>
                <p>
                  Our cross-disciplinary team of tech experts, psychologists,
                  designers, and data scientists collaborates to fuse
                  technological sophistication with a deep understanding of
                  human needs, creating applications that resonate with users on
                  a personal level.
                </p>
              </div>
            </div>
          </div>
          <div className="w-layout-grid _4-features-grid">
            {features.map((feature) => (
              <div key={feature.id} className="feature-link-holder">
                <img
                  src={feature.imageUrl}
                  loading="lazy"
                  sizes="150px"
                  srcSet={feature.imageSrcSet}
                  alt=""
                  className="feature-small-icon"
                  style={commonStyle}
                />
                <div className="feature-small-wrapper" style={commonStyle}>
                  <div className="feature-small-title">{feature.title}</div>
                </div>
                <div className="fade-in-on-scroll" style={commonStyle}>
                  <p className="grey-text">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
