import React from "react";
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import Integrations from "../components/Integrations/Integrations";
import Solutions from "../components/Solutions/Solutions";
import Form from "../components/Form/Form";
import Projects from "../components/Projects/Projects";

import "../styles/webflow.css";

export default function index() {
  return (
    <div>
      <Hero />
      <Services />
      <Integrations />
      <Solutions />
      <Projects />
    </div>
  );
}
