// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.svteknoloji.com";

const config = {
  title: "SV Teknoloji",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "SV Teknoloji",
  description:
    "With the power of AI, we make app and user provisioning affordable and effortless. Streamline user and app provisioning now!",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  image:
    "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full", // Path to your image you placed in the 'static' folder
  logoBlue:
    "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full",
  postThumbnail:
    "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full",
  twitterUsername: "svteknoloji",
  blogDesktopBanner:
    "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full",
  blogMobileBanner:
    "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full",
  airtableTables: ["Blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: [
    "slug",
    "title",
    "description",
    "headline",
    "published",
    "shareOnTwitter",
    "imageUrl",
    "shareOnWhatsapp",
    "shareOnSms",
  ],
};

module.exports = config;
