import React from "react";

const Hero = () => (
  <div className="section hero-section" style={{ paddingTop: 300 }}>
    <div className="container hero-container">
      <div
        className="hero-section-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, -250px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
      >
        <div className="hero-section-container">
          <div
            className="animate-on-load-01"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
          >
            <div className="quick-news">
              <div className="quick-news-text">
                Global software development company
              </div>
            </div>
          </div>
          <div className="hero-section-header-holder">
            <div
              className="animate-on-load-02"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d",
              }}
            >
              <h1 className="hero-title">Crafting the Future:</h1>
            </div>
          </div>
          <div
            className="hero-section-paragraph-holder"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <p>Where AI Meets Ingenious App Design and Development</p>
          </div>
          <div
            className="hero-section-button-holder"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <a href="/team" target="_blank" className="button w-button">
              Meet the Team
            </a>
            <a href="/projects" className="button outline w-button">
              Our Products
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      className="hero-bacgkround"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d",
      }}
    >
      <img
        src="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/653842812c138883e7a7b101_Hero%20BG.svg"
        loading="lazy"
        alt=""
        className="hero-background-image"
      />
    </div>
  </div>
);

export default Hero;
