import React from "react";

const SolutionsSection = () => {
  return (
    <div id="Solutions" className="section" style={{ marginTop: -150 }}>
      <div className="container">
        <div className="gradient-box">
          <div className="cta-paddings">
            <div className="center-text">
              <div className="title-holder">
                <div className="title-container">
                  <h2 className="title">What we built?</h2>
                </div>
                <div className="paragraph-holder bigger">
                  <p>
                    Our team of experts have been working hard to bring you
                    these unique projects. Reach out to us to learn more about
                    how we can help you with your next project.
                  </p>
                </div>
              </div>
            </div>
            <div className="cta-icons-holder">
              {/* Icon Containers */}
              <IconContainer
                id="6cd40c82-9495-8ddf-5606-dd892bc0079f"
                iconUrl="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537c445a88fa0fda464c8f0_Time.svg"
                description="Marketing Focused AI Marketing Video Player "
              />
              <IconContainer
                id="6cd40c82-9495-8ddf-5606-dd892bc007a5"
                iconUrl="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537c445ab61798b1e462c8f_Cloud.svg"
                description="Image Compression and Optimization AI"
              />
              <IconContainer
                id="6cd40c82-9495-8ddf-5606-dd892bc0079f"
                iconUrl="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537c445a88fa0fda464c8f0_Time.svg"
                description="Object Macher AI"
              />
              <IconContainer
                id="6cd40c82-9495-8ddf-5606-dd892bc007a5"
                iconUrl="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537c445ab61798b1e462c8f_Cloud.svg"
                description="Automated Mobile Video Advertising Optimization AI"
              />
            </div>
            <div className="cta-button-holder">
              <a href="/team" target="_blank" className="button w-button">
                Meet the Team
              </a>
              <a href="/projects" className="button outline w-button">
                Project Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IconContainer = ({ id, iconUrl, description }) => (
  <div data-w-id={id} className="early-access-icons-container center">
    <img
      src={iconUrl}
      loading="lazy"
      alt=""
      className="early-access-icon bigger"
    />
    <div className="early-access-paragraph">{description}</div>
  </div>
);

export default SolutionsSection;
