import * as React from "react";

function ErrorPage() {
  return (
    <>
      <section id="error-container">
        <span>404 ERROR</span>
        <h2>
          <strong>We can’t find the page</strong> <br /> you are looking for.
        </h2>
        <p>
          <a className="primary-cta-button" href="/">
            BACK TO HOME
          </a>
        </p>
        <div className="error-img-left">
          <img
            src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/v1645615491/website/error-img-left.webp"
            alt=""
          />
        </div>
        <div className="error-img-right">
          <img
            src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/v1645615491/website/error-img-right.webp"
            alt=""
          />
        </div>
      </section>
    </>
  );
}

export default ErrorPage;
