import React from "react";

const FAQSection = () => {
  const transformStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  const questions = [
    {
      question:
        "Smart, Personalizable, Advertising Ecosystem Supporting Mobile Video Player Software and Analysis Library Development with Artificial Intelligence and Semantic Analysis ",
      answer:
        "In the current landscape, the majority of video advertisements disrupt the user's viewing experience. Many video publishers struggle to display real-time, personalized ads, leading to both revenue loss and a diminishing user experience. Our Mobile Player addresses this issue with its Enhanced Interactive Personalized Advertising technology. This technology selects the most relevant ads in real time, based on users' past internet searches and browsing history. Importantly, these ads are integrated seamlessly into the video content, avoiding any disruption to the viewing experience. Consequently, Mobile Player creates a win-win situation, enhancing user engagement with content and increasing revenue for video publishers. The player is designed for mobile app developers and owners, allowing them to personalize its interface and content. The upcoming version will integrate VAST technology, a global standard for digital video ad display and measurement.",
    },
    {
      question:
        "Development of High-Speed Compression and Optimization of Images for Fast Loading of Internet and Mobile Applications and Cost Savings Using Edge Computing Based Graphic Processing ",
      answer:
        "Our project aims to develop an easy-to-integrate image compression and optimization service for companies utilizing digital visuals on their websites or mobile applications. This service, running on our specialized software infrastructure, will use a graphic processing unit to significantly optimize images, resulting in considerable savings in bandwidth usage and monthly server and broadband costs. Additionally, our service will store companies' digital visuals on our servers, further reducing their server storage usage and related expenses. Websites and mobile applications using our service can perform digital image optimization and compression tailored to all platforms and screen resolutions. This will enhance their performance speed and significantly reduce the data consumption for their visitors. We plan to deploy servers in specific regions of Turkey to securely store digital visual content and deliver it rapidly to visitors through edge computing technology.",
    },
    {
      question:
        "Systems and Methods for Matching Objects in Camera or Video to Emojis, Pictures, Stickers, and/or 3D Objects ",
      answer:
        "Our project focuses on developing systems and methods for contextually providing smart 2D and 3D objects on screens, videos, or cameras. The systems and methods are designed to recognize objects in visual media and generate related smart texts, emojis, stickers, and objects that interact with current items on camera or screen. This includes contextual searches for 2D or 3D objects relevant to the content. In some applications, these systems provide smart 2D and 3D objects that interact with one or more items in visual media. They also establish relationships among multiple objects in a single visual medium or between objects in the media and selected 2D/3D objects for overlay. Suggestions for these 2D and 3D objects are based on visuals in the environment, user location, or previously used images, videos, 2D, or 3D objects. This innovation, especially aimed at social media applications, where users constantly share photos and seek suitable smart 2D or 3D objects, is expected to create significant impact, starting from Silicon Valley and extending globally.",
    },
    {
      question:
        "Mobile Video Advertising Optimization Library and Automated Ad Format Compatibility ",
      answer:
        "Our project is centered on developing algorithms and a graph database to comprehensively analyze user preferences in film genres, desired movies, upcoming movie-related content, and friends' movie interests. This analysis aims to provide the most accurate video recommendations to users. Additionally, we have identified a need to analyze these videos and their viewers. Designed for mobile app developers, our smart, customizable mobile video players with dynamic interface and content can collect and analyze advanced real-time statistics of your videos. These players allow dynamic content alteration during playback through specialized controls. Our company, offering two primary discovery areas for upcoming and current movies, aims to dominate the market not only in our country but also in the USA, by providing analysis opportunities through our mobile video player.",
    },
    {
      question: "Coming soon: Workwise AI ",
      answer:
        "SV Teknoloji, a premier Turkish software company with roots in Silicon Valley, specializes in AI-driven solutions to streamline remote work efficiency in the post-pandemic era. The company's focus is on developing innovative tools to effectively manage remote work styles and hours, addressing key challenges in modern work environments. SV Teknoloji's AI-based approach targets operational needs arising from the shift to remote work, underlining the importance of R&D, innovation, and design in enhancing work productivity. The firm places a strong emphasis on customer feedback from startups and medium to large-scale firms to refine and adapt its products. This feedback is crucial for ensuring the user-friendliness of systems and the relevancy of data analysis. By integrating AI into their solutions, SV Teknoloji distinguishes itself in the competitive remote work solutions market, offering unique value through customized services and comprehensive data insights. The company's strategy revolves around continuous improvement and innovation, informed by internal and external stakeholder input, to meet the evolving demands of the remote work landscape.",
    },
    // ... more questions
  ];

  return (
    <div className="section">
      <div className="divider margins"></div>
      <div id="Product" className="early-access-features">
        <div className="container">
          <div className="center-text">
            <div className="title-holder">
              <div className="title-container" style={transformStyle}>
                <h2 className="title">Our latest projects</h2>
              </div>
              <div className="paragraph-holder" style={transformStyle}>
                <p>
                  Click and learn more about our latest projects and details.
                </p>
              </div>
            </div>
          </div>
          <div className="questions-holder">
            {questions.map((q, index) => (
              <div
                key={index}
                className="questions-container"
                style={transformStyle}
              >
                <div className="question">
                  <img
                    src="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/6537fb12a97fc8bc4cf6c809_Arrow%20Up.svg"
                    loading="lazy"
                    alt=""
                    className="question-icon"
                    style={{
                      ...transformStyle,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)",
                    }}
                  />
                  <div className="question-text">{q.question}</div>
                </div>
                <div className="answer-holder" style={{ height: "0px" }}>
                  <div className="answer-text">{q.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
