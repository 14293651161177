/* eslint-disable no-script-url */
import React from "react";

import "../../styles/webflow.css";

const Menu = () => {
  return (
    <header>
      <div className="fixed-navbar">
        <div className="navbar w-nav" style={{ opacity: 1 }} role="banner">
          <div className="container navbar-container">
            <div className="navbar-holder">
              <div className="navbar-container">
                <a
                  href="/"
                  aria-current="page"
                  className="brand w-nav-brand w--current"
                  aria-label="home"
                >
                  <img
                    src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/bbd8422a-8dcd-430b-8d49-130ddf29e200/full"
                    loading="lazy"
                    alt=""
                    className="brand-image"
                  />
                </a>
                <nav role="navigation" className="nav-menu w-nav-menu">
                  <div className="nav-menu-link-holder">
                    <div className="nav-menu-link-container">
                      <div className="nav-links">
                        <a href="/about-us" className="nav-link w-nav-link">
                          About Us
                        </a>
                        <a href="/projects" className="nav-link w-nav-link">
                          Projects
                        </a>
                        <a href="/blog" className="nav-link w-nav-link">
                          Blog
                        </a>
                        <a href="/team" className="nav-link w-nav-link">
                          Team
                        </a>
                      </div>
                    </div>
                  </div>
                </nav>
                {/* ... more code ... */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
