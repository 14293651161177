import * as React from "react";

import "../../styles/webflow.css";

const Footer = () => {
  const commonStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };
  return (
    <footer>
      <div className="section">
        <div className="footer">
          <div className="container">
            <div className="footer-wrapper">
              <a
                href="/"
                aria-current="page"
                className="footer-brand w-inline-block w--current"
                style={commonStyle}
              >
                <img
                  src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/bbd8422a-8dcd-430b-8d49-130ddf29e200/full"
                  loading="lazy"
                  alt=""
                  className="footer-brand-image"
                />
                <div className="footer-paragraph-holder">
                  <p>
                    Crafting the Future: Where AI Meets Ingenious App Design and
                    Development
                  </p>
                </div>
              </a>
              <div className="footer-content">
                <div className="footer-block">
                  <div className="title-small" style={commonStyle}>
                    Company
                  </div>
                  <a
                    href="/about-us"
                    className="footer-link"
                    style={commonStyle}
                  >
                    About Us
                  </a>
                  <a
                    href="/projects"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Projects
                  </a>
                  <a href="/team" className="footer-link" style={commonStyle}>
                    Team
                  </a>
                  <a
                    href="mailto:emre@svteknoloji.com"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Contact Us
                  </a>
                </div>
                <div className="footer-block">
                  <div className="title-small" style={commonStyle}>
                    Social media
                  </div>
                  <a
                    href="http://instagram.com"
                    target="_blank"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Instagram
                  </a>
                  <a
                    href="http://fb.com"
                    target="_blank"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Facebook
                  </a>
                  <a
                    href="http://linkedin.com"
                    target="_blank"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Linkedin
                  </a>
                  <a
                    href="http://twitter.com"
                    target="_blank"
                    className="footer-link"
                    style={commonStyle}
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-divider" style={commonStyle}>
              <div className="footer-copyright-holder">
                <div className="footer-copyright-center">
                  Created by{" "}
                  <a
                    href="http://svteknoloji.com/"
                    target="_blank"
                    className="white-link"
                  >
                    SV Teknoloji
                  </a>
                </div>
              </div>
              <div className="footer-copyright-holder">
                <div
                  className="footer-copyright-center"
                  style={{ color: "white" }}
                >
                  Designed in{" "}
                  <span role="img" aria-label="Sun">
                    ☀️
                  </span>{" "}
                  Istanbul &amp; Built Globally With{" "}
                  <span role="img" aria-label="Heart">
                    ❤️
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script
        src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6537b1db509ef0817e8f4b5d"
        type="text/javascript"
        integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
        crossorigin="anonymous"
      ></script>
      <script
        src="https://assets-global.website-files.com/6537b1db509ef0817e8f4b5d/js/webflow.67d20a82c.js"
        type="text/javascript"
      ></script>
    </footer>
  );
};

export default Footer;
