import React from "react";
import PropTypes from "prop-types";

import "../styles/styles.css";

function RootLayout({ children }) {
  if (children) {
    return <div>{children}</div>;
  }
  return <div />;
}

RootLayout.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line
};

export default RootLayout;
