import React from "react";

const CTASubscriptionForm = () => {
  const formStyle = {
    opacity: 1,
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement submission logic here
  };

  return (
    <div className="cta-holder">
      <div className="cta-container">
        <div className="cta-form-holder">
          <div className="cta-heading-holder" style={formStyle}>
            <div className="cta-heading">Get early access</div>
            <div className="cta-paragraph-holder">
              <p>
                To get early access to our AI-powered software development
                products, enter your email below.
              </p>
            </div>
          </div>
          <div className="form-block w-form">
            <form
              id="Early-Access-Emails"
              name="wf-form-Early-Access-Emails"
              className="form-holder"
              onSubmit={handleSubmit}
              aria-label="Early Access Emails"
            >
              <div className="form" style={formStyle}>
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Enter email here"
                  id="Early-Access-Emails"
                  required
                />
                <input
                  type="submit"
                  value="Enter"
                  className="submit-button w-button"
                />
              </div>
              {/* <p style={formStyle}>Aliquam et tellus urna. Phasellus eget</p> */}
            </form>
            <div
              className="thank-you-message w-form-done"
              tabIndex="-1"
              role="region"
              aria-label="Early Access Emails success"
            >
              <div>
                <span className="white-text">Thank you! </span>
                <br />
                Your submission has been received!
              </div>
            </div>
            <div
              className="error-message w-form-fail"
              tabIndex="-1"
              role="region"
              aria-label="Early Access Emails failure"
            >
              <div>
                <span className="white-text">
                  Oops! <br />
                </span>
                Something went wrong! Try again later
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASubscriptionForm;
