import React from "react";

const SectionComponent = () => {
  const commonStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  const logoFeatureStyle = {
    willChange: "transform",
    transform:
      "translate3d(0px, -25.7871px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  };

  // Add other styles as needed

  return (
    <div className="section" style={{ marginTop: -50 }}>
      <div id="Product" className="early-access-features">
        <div className="container">
          <div className="early-access-wrapper">
            <div className="early-access-gird">
              <div className="early-access-content-holder">
                <div className="fade-in-on-scroll" style={commonStyle}>
                  <div className="tagline">Who we are?</div>
                </div>
                <div className="early-access-title-holder" style={commonStyle}>
                  <h2 className="title">About Us</h2>
                </div>
                <div
                  className="early-access-paraghaph-holder"
                  style={commonStyle}
                >
                  <div
                    // className="hero-section-paragraph-holder"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <p>
                      At SV Teknoloji, we're at the forefront of integrating
                      advanced artificial intelligence into app design and
                      development, going beyond traditional automation. Our
                      unique approach is centered around understanding user
                      behavior, customizing experiences, and predicting future
                      trends. We embrace a user-centric design philosophy,
                      ensuring our applications are not only intuitive and
                      accessible but also deeply engaging.
                    </p>
                    <p>
                      Our cross-disciplinary team of tech experts,
                      psychologists, designers, and data scientists collaborates
                      to fuse technological sophistication with a deep
                      understanding of human needs, creating applications that
                      resonate with users on a personal level.
                    </p>
                    <p>
                      We are deeply committed to ethical AI governance and
                      sustainable development. Upholding the highest standards
                      of data privacy and bias prevention in AI algorithms is a
                      key part of our mission, alongside optimizing app
                      performance to minimize environmental impact.
                    </p>
                    <p>
                      As pioneers in our field, we are also leading the
                      development of advanced AI-powered tools and platforms.
                      These innovations aim to democratize the app development
                      process, making it more accessible to a diverse range of
                      creators worldwide. By tackling challenges head-on and
                      seizing new opportunities, we are dedicated to driving
                      groundbreaking innovations and shaping new business models
                      in the dynamic world of app development.
                    </p>
                  </div>
                </div>
                <div className="early-access-icons-holder" style={commonStyle}>
                  {/* Icon containers */}
                </div>
              </div>
              <div className="early-access-card">
                <img src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/990c5933-b617-4690-8a54-9f6583fd3100/full"></img>
                {/* Early access card content */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionComponent;
