import * as React from "react";
import PropTypes from "prop-types";

function ContentLayout({ children }) {
  if (children) {
    return (
      <div style={{ minHeight: "80vh", paddingTop: "var(--menu-height)" }}>
        {children}
      </div>
    );
  }
  return <div />;
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired, // eslint-disable-line
};

export default ContentLayout;
