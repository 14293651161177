import * as React from 'react';
import PropTypes from 'prop-types';

import SEO from '../components/SEO/SEO';
import Micro from './Micro';
import ContentLayout from './ContentLayout';
import Menu from '../components/Menu/Menu';
import Footer from '../components/Footer/Footer';
import '../builder-settings';

function PageLayout({ children }) {
  return (
    <>
      <SEO />
      <Micro>
        <Menu />
      </Micro>
      <ContentLayout>{children}</ContentLayout>
      <Micro>
        <Footer />
      </Micro>
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired, // eslint-disable-line
};

export default PageLayout;
