import React from "react";

const SectionComponent = () => {
  const commonStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  const logoFeatureStyle = {
    willChange: "transform",
    transform:
      "translate3d(0px, -25.7871px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  };

  // Add other styles as needed

  return (
    <div className="section" style={{ marginTop: -250 }}>
      <div id="Product" className="early-access-features">
        <div className="container">
          <div className="early-access-wrapper">
            <div className="early-access-gird">
              <div className="early-access-content-holder">
                <div className="fade-in-on-scroll" style={commonStyle}>
                  <div className="tagline">Intergrations</div>
                </div>
                <div className="early-access-title-holder" style={commonStyle}>
                  <h2 className="title">
                    Powerful features for working smarter
                  </h2>
                </div>
                <div
                  className="early-access-paraghaph-holder"
                  style={commonStyle}
                >
                  <p className="grey-text">
                    Let us our AI-powered software development platform help you
                    integrate your loved ones!
                  </p>
                </div>
                <div className="early-access-icons-holder" style={commonStyle}>
                  {/* Icon containers */}
                </div>
              </div>
              <div className="early-access-card">
                <img src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/13cf2c17-56df-46a9-d284-426ce95a8f00/full"></img>
                {/* Early access card content */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionComponent;
